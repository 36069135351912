import { useEffect } from "react";

const Snowfall = () => {
  useEffect(() => {
    const currentDate = new Date();
    const startSnowfallDate = new Date(currentDate.getFullYear(), 10, 1); // November 1
    const endSnowfallDate = new Date(currentDate.getFullYear() + 2, 0, 1); // Fabruary 1

    if (currentDate >= startSnowfallDate && currentDate <= endSnowfallDate) {
      const maxFlakes = 50; // Maximum number of snowflakes
      const flakes: HTMLDivElement[] = [];
      const snowflakeCharacters = ["❅", "❄", '♡',"❆"]; // Snowflake characters

      const interval = setInterval(() => {
        if (flakes.length < maxFlakes) {
          createSnowflake();
        }
      }, 800);

      const createSnowflake = () => {
        const snowflake = document.createElement("div");
        snowflake.className = "snowflake";

        const randomChar =
          snowflakeCharacters[Math.floor(Math.random() * snowflakeCharacters.length)];
        snowflake.innerHTML = randomChar;

        document.body.appendChild(snowflake);
        flakes.push(snowflake);

        const startPos = Math.random() * window.innerWidth;
        const startOpacity = Math.random();
        const duration = Math.random() * 3 + 5;
        const size = Math.random() * 20 + 10; // Random size

        snowflake.style.fontSize = `${size}px`;
        snowflake.style.opacity = startOpacity.toString();
        snowflake.style.position = "fixed";
        snowflake.style.top = "0";
        snowflake.style.left = `${startPos}px`;

        const rotationDirection = Math.random() > 0.5 ? 1 : -1;

        snowflake.animate(
          [
            { transform: `translate(0, 0) rotate(0deg)` },
            { transform: `translate(0, 100vh) rotate(${rotationDirection * 360}deg)` },
          ],
          {
            duration: duration * 1000,
            easing: "linear",
            iterations: Infinity,
          }
        );
      };

      return () => {
        clearInterval(interval);
        flakes.forEach((flake) => flake.remove());
      };
    }
  }, []);

  return (
    <div>
      <style>{`
        .snowflake {
          user-select: none;
          pointer-events: none;
          z-index: 1000;
          color: #a9cce3;
          font-weight: bold;
          white-space: nowrap;
        }
      `}</style>
    </div>
  );
};

export default Snowfall;
