import styled from "styled-components";
import Pink from "./Pink";

const Title = styled.h2`
  margin-top: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;
  justify-content: space-evenly;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
`;


const StyledList = styled.div`
  ul {
    text-align: left;
    justify-content: center;
    padding: 0 1em 0 1em;
    margin: 0 auto;

  }

   li {
    &:not(:last-child) {
      margin: 30px 0 50px 0;
    }
  }
`;


const schools = [
  {
    url: "https://www.tsukuba.ac.jp/en/",
    school: "University of Tsukuba – BA in Information Science",
    dateAndPlace: "March 2021, Japan",
    content:
      "Received an award of excellence for best project (top 10/120) in information science class of 2019",
  },
];

const companies = [
  {
    url: "https://www.allsquad.io/about",
    company: "Squad Labs Ltd – Frontend Engineer",
    dateAndPlace: "May 2024 - Present, London, United Kingdom",
  },
  {
    url: "https://akord.com/",
    company: "Akord – Community & Partnerships",
    dateAndPlace: "March 2022 - June 2023, London, United Kingdom",
  },
  {
    url: "https://www.mylittleparis.com/",
    company: "My Little Box Japan – Digital Merchandising Intern",
    dateAndPlace: "February 2017 - June 2018, Tokyo, Japan",
  },
  {
    url: "https://line.me/en/",
    company: "LINE Corporation – Editor",
    dateAndPlace: "September 2015 - December 2016, Tokyo, Japan",
  },
];

function Resume() {
  return (
    <>
      <Pink>
        <Title id="cv">Education & Experience</Title>
      </Pink>
      <Container>
        <StyledList>
          <h3>Education</h3>
          <ul>
            {schools.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.url}>
                    <h3>{item.school}</h3>
                  </a>
                  <p className="description">{item.dateAndPlace}</p>
                  <p className="description">{item.content}</p>
                </li>
              );
            })}
          </ul>
        </StyledList>
        <StyledList>
          <h3>Experience</h3>
          <ul>
            {companies.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.url}>
                    <h3>{item.company}</h3>
                  </a>
                  <p className="description">{item.dateAndPlace}</p>
                </li>
              );
            })}
          </ul>
        </StyledList>
      </Container>
      <a href="https://www.linkedin.com/in/ai-miyuki/">
        <h4>View more on LinkedIn →</h4>
      </a>
    </>
  );
}

export default Resume;
